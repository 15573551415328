body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
}

.header {
  background-color: #001529;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
}

.header .logo {
  color: #ffffff;
  font-size: 1.8rem;
  font-weight: bold;
}

.desktop-menu {
  display: flex;
  justify-content: center;
}

.mobile-menu-icon {
  display: none;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
}

.firstImpression {
  text-align: left;
}

.firstImpression h3 {
  font-size: 1.8rem;
}

.firstImpression p {
  font-size: 1.2rem;
  margin-bottom: 40px;
}

.button {
  margin: 10px;
  font-size: 1rem;
}

.content {
  padding: 80px 30px;
}

.content h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #000000;
}

.content h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.content p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 30px;
}

#newsletterEmail {
  max-width: 400px;
  margin-bottom: 20px;
}

button {
  background-color: #1890ff;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: #40a9ff;
}

.footer {
  background-color: #001529;
  color: white;
  padding: 20px;
  text-align: center;
}

.footer hr {
  border-color: #ffffff22;
  margin: 20px 0;
}

.footer p,
.footer a {
  color: white;
  font-size: 0.9rem;
}

.footer a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .desktop-menu {
    display: none;
  }

  .mobile-menu-icon {
    display: block;
  }

  .firstImpression {
    padding: 10px;
  }

  .firstImpression h1 {
    font-size: 3rem;
  }

  .firstImpression h3 {
    font-size: 1.5rem;
  }

  .firstImpression p {
    font-size: 1rem;
  }

  .content {
    padding: 30px 10px;
    justify-content: center;
  }

  .content h2 {
    text-align: center;
  }

  .footer {
    padding: 15px;
  }
}